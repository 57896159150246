.__wrapper {
  background-color: #fff;
  padding: 25px 20px;
  box-sizing: border-box;
  display: flex;
  align-items: stretch;
  justify-content: center;
  gap: 20px;

  .list {
    background-color: #fff;
    position: absolute;
    top: 33px;
    right: 0;
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 5px 5px;
    padding-left: 7px;

    li {
      margin-top: 7px;
      cursor: pointer;
      display: flex;

      &:hover {
        background-color: #eee;
      }

      .label {
        display: block;
        width: 100%;
      }
    }
  }
}