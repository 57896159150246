.__wrapper {
  background-color: var(--sidebar-bg);
  min-height: 100vh;
  width: 90px;
  padding: 30px 0 20px 0;
  height: 100%;

  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 40px;
  box-sizing: border-box;
}