.__risk_level {
  height: 22px;
  background-color: var(--high-border-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 0 10px;

  p {
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    color: #f8fbff;
  }
}