.__wrapper {
  width: 35%;
  box-sizing: border-box;
  border-radius: 5px;

  @media screen and (max-width: 1360px) {
    display: none;
  }

  .listOfPatients {
    overflow-y: auto;
    height: calc(100vh - 190px);

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
}

.__mobile_wrapper {
  width: 410px;
  box-sizing: border-box;

  .listOfPatients {
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
}

.searchBar {
  padding: 15px 10px;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-right: 15px;
  background-color: var(--selected-bg);
  position: sticky;
  top: 0;

  .searchInput {
    width: 100%;
    border: none;
    outline: none;
    background-color: transparent;
    padding: 5px;
    font-size: 14px;
    color: var(--text-color);

    &:focus {
      outline: none;
      border: none;
    }
  }
}
