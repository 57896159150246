.__wrapper {
  .__noPatient {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 20px;

    h2 {
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 1.5rem;
      color: var(--primary-color);
    }
  }
}