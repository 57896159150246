.__wrapper {
    background-color: #fff;
    min-height: 85vh;
    padding: 15px;
    border-radius: 8px;

    h2 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 15px;
        color: #757575;
    }
}