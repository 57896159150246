.__wrapper {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 20px 30px;
  background-color: #fff;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  color: inherit;
  border-left: 2px solid;

  &:hover {
    background-color: var(--selected-bg);
  }

  .__condition {
    h2 {
      font-size: 14px;
      border: 5px solid;
      height: 55px;
      width: 55px;
      border-radius: 50%;
      background-color: var(--selected-bg);
      color: var(--sidebar-bg);
      font-weight: 700;
      line-height: 17px;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    .patient__details {
      display: flex;
      align-items: center;
      gap: 10px;
      color: var(--primary-color);

      .__patient_id {
        font-size: 1rem;
        font-weight: 700;
        color: inherit;
        text-decoration: none;
      }

      .__patient_name,
      .__patient_range {
        text-decoration: none;
        color: inherit;
        font-size: 1rem;
        font-weight: 600;
      }

      .dot {
        width: 5px;
        height: 5px;
        border-radius: 50%;

        background: rgba(48, 62, 101, 0.2);
      }
    }

    .__patient_info {
      display: flex;
      align-items: center;
      gap: 10px;

      .__patient_room,
      .__patient_condition,
      .__patient_admitted {
        color: rgba(48, 62, 101, 0.7);
        font-size: 12px;
        line-height: 15px;
        font-weight: 400;
        white-space: nowrap;
      }

      .dot {
        width: 3px;
        height: 3px;
        background: rgba(48, 62, 101, 0.2);
      }
    }
  }
}
